const messages = {
  keypoint: {
    more: 'En savoir +'
  },
  question: {
    number: 'Question n°',
    question: 'Question',
    win: 'Bravo&nbsp;!',
    lose: 'Tu y es presque&nbsp;!',
    more: 'En apprendre d’avantage',
  },
  end: {
    well: 'Bravo',
    title: 'Tu es arrivé au bout de l\'aventure !',
    text1: 'Pour participer au tirage au sort et tenter de gagner une aventure avec Dacia,',
    text2: 'inscris-toi et complète le formulaire.',
    conditions: 'J’accepte sans réserve <a href="/reglement__fr.pdf" target="_blank">le règlement du jeu</a>',
    text3: 'La participation au jeu est ouverte aux personnes physiques majeures dont le domicile est en France métropolitaine',
  },
  share: 'Restez connecté avec Dacia',
  step: 'Étape',
  help: {
    1: 'Je ne trouve pas l’emblème dacia',
    2: 'J\’utilise mon joker'
  },
  pages: {
    personal: 'Données Personnelles',
    cookies: 'Cookies',
    about: 'Mentions Légales',
    legals1: 'Le site « daciaaventure.dacia.fr » est édité par :Renault s.a.s, société par actions simplifiée au capital de 533.941.113 euros, dont le siège social est situé 122-122 bis avenue du Général',
    legals2: 'Leclerc – 92100 Boulogne Billancourt cedex, immatriculée au R.C.S. de Nanterre sous le numéro 780 129 987 (ci-après « RENAULT »),',
    legals3: 'Directeur de la publication : Justine Kohr',
    legals4: 'Hébergeur daciaaventure.dacia.fr : Moma Events',
  },
  tutos: {
    title1: 'Ton objectif',
    title2: 'À chaque étape',
    subTitle: 'Nous avons dissimulé 6 QR codes sur le stand.',
    obj: 'Trouver au moins 5 de ces QR codes pour participer au tirage au sort et remporter ton aventure.'
  },
  legals: 'La participation au jeu est ouverte aux personnes physiques majeures dont le domicile est en France métropolitaine.'
}

export default messages
