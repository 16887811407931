<template>
  <div class="keypoint-view u-wrapper-panel">
    <!-- START MEDIA KEYPOINTS -->
    <template v-for="keypoint in keypointsMedia">
      <transition-group name="t-fader--delayed">
        <keypoint
          :key="keypoint.slug"
          :title="keypoint.title"
          :position="keypoint.position[0]"
          :to="{ ...this.$local('Media'), params: { pano: $route.params.pano, media: keypoint.slug } }"
          :isComplete="keypoint.isComplete"
          v-if="keypoint.isActive && !isTransition"
        />
      </transition-group>
    </template>
    <!-- END MEDIA KEYPOINTS -->

    <!-- START QUESTION KEYPOINTS -->
    <transition name="t-fader--delayed">
      <div v-if="!isTransition">
        <template v-for="(keypoint, index) in keypointsQuestion">
          <transition-group>
            <keypoint-q
              :key="keypoint.slug"
              :title="keypoint.title"
              :position="keypoint.position[0]"
              :size="keypoint.scale ? parseFloat(keypoint.scale) * 2 * .01 + .1 : .5"
              :rotation="keypoint.look[0]"
              :to="{ ...this.$local('Question'), params: { question: keypoint.slug } }"
              :isComplete="keypoint.isComplete"
              v-if="!keypoint.isComplete"
            />
          </transition-group>
        </template>
      </div>
    </transition>
    <!-- END QUESTION KEYPOINTS -->

    <transition name="t-fader--delayed">
      <div
        class="u-wrapper-panel u-pointer-none"
        v-if="!isTransition && !$route.name.includes('Media') && !$route.name.includes('Question')"
      >
        <!-- START ARROWS 360 -->
        <arrow-neighbor
          class="u-pointer-all"
           v-for="(neighbor, index) in neighbors"
          :position="{
            x: cameraPos.x - neighbor.relativeCartesian.x * spaceArrow,
            y: cameraPos.y - 100,
            z: cameraPos.z - neighbor.relativeCartesian.z * spaceArrow}
          "
          :lookAt="{
            x: cameraPos.x - neighbor.relativeCartesian.x * spaceArrow * 10,
            y: cameraPos.y + 700,
            z: cameraPos.z - neighbor.relativeCartesian.z * spaceArrow * 10}
          "
          :to="isCustomNeighbors ? {...this.$local('Pano'), params: { pano: this.neighbors[index].dacia360[0].slug }} : { ...this.$local('Pano'), params: { pano: this.neighbors[index].slug } }"
        />
            <!-- y: cameraPos.y - 100,
            y: cameraPos.y + 1200, -->
          <!-- +1200 is a shitty fix to make arrows lie on the floor, like a localRotation -->
        <!-- END ARROWS 360 -->

        <!-- START DOT NEXT -->
        <!-- <transition name="t-fader--delayed">
          <template v-if="!$route.query.debug && !isTransition">
            <interface-next
              v-if="indexClickable !== -1 && neighbors[indexClickable] && !$device.isTouch"
              :position="pointerPos"
              :lookAt="{
                x: cameraPos.x - neighbors[indexClickable].relativeCartesian.x * 400,
                y: cameraPos.y - neighbors[indexClickable].relativeCartesian.y,
                z: cameraPos.z - neighbors[indexClickable].relativeCartesian.z * 400
              }"
            />
          </template>
        </transition> -->
        <!-- END DOT NEXT -->
      </div>
    </transition>

    <!-- START DEBUGGER -->
    <debugger :pointerPos="pointerPos" v-if="$route.query.debug" />
    <!-- END DEBUGGER -->

    <transition name="t-fader">
      <header-pano v-if="!$route.name.includes('Media')" :isQuestion="$route.name.includes('Question')" />
    </transition>

    <transition :name="$route.name.includes('Media') ? 't-translate--down--delayed' : 't-fader'">
      <media v-if="$route.name.includes('Media')" @close="onClose" />
    </transition>

    <transition :name="$device.isDesktop ? 't-fader--delayed' : 't-translate--down--delayed'">
      <question v-if="$route.name.includes('Question')" @close="onClose" />
    </transition>
  </div>
</template>

<script>
import InterfaceNext from '@/components/InterfaceNext'
import Keypoint from '@/components/common/Keypoint'
import KeypointQ from '@/components/common/KeypointQ'
import ArrowNeighbor from '@/components/ArrowNeighbor'
import Debugger from '@/components/Debugger'
import HeaderPano from '@/components/HeaderPano'

import Media from '@/views/keypoint/Media'
import Question from '@/views/keypoint/Question'

import { webGL } from '@/webGL/WebGL'

import { Vector3, Vector2, SphereGeometry, Mesh, MeshBasicMaterial, BackSide, AxesHelper } from 'three'

import { pointer } from 'shimmer'

import { cartesianToPolar } from '@/utils/Maths'
import { CameraLook } from '@/utils/CameraLook'

import { textureLib } from '@/webGL/TextureLib'

import { setup } from '@/assets/data'

import gsap from 'gsap'

const maxNeighbors = 5

export default {
  data () {
    return {
      isTransition: false,
      indexClickable: -1,
      pointerPos: {x: 0, y:0, z: 0},
      cameraPos: {x: 0, y:0, z: 0},
      spaceArrow: 200
    }
  },

  components: {
    Keypoint,
    InterfaceNext,
    ArrowNeighbor,
    KeypointQ,
    HeaderPano,
    Debugger,
    Media,
    Question
  },

  computed: {
    data () {
       return this.$store.getters['data/view'](this.$route.params.pano)
    },

    keypointsMedia () {
      if (!this.data) { return }
      let keypoints = this.data.customKeypoints.filter(keypoint => !keypoint.questions.length)
      if (!keypoints.length) {
        // keypoints = this.$store.getters['data/keypoints'].filter(keypoint => !keypoint.questions.length)
        return []
      }

      return computeDist(keypoints, this.data.position)
    },

    keypointsQuestion () {
      if (!this.data) { return }
      let keypoints = this.data.customKeypoints.filter(keypoint => keypoint.questions.length)
      if (!keypoints.length) {
        // keypoints = this.$store.getters['data/questions']
        return []
      }

      return computeDist(keypoints, this.data.position)
    },

    isCustomNeighbors () {
      if (!this.data) { return false }
      return !!this.data.customNeighbors.length
    },

    currentQuestionIndex () {
      return this.$store.getters['data/currentQuestionIndex']
    },

    neighbors () {
      if (!this.data) { return [] }

      let neighbors = this.isCustomNeighbors ? this.data.customNeighbors : this.$store.getters['data/views']
        // we remove keypoint where we are
      neighbors = neighbors.filter(neighbor => neighbor.slug !== this.$route.params.pano)
        // we calculate relativeCartesian and relativePolar
        .map(neighbor => {
          let relativeCartesian = new Vector3()
          relativeCartesian.x = this.data.position[0].x - neighbor.position[0].x
          relativeCartesian.y = this.data.position[0].y - neighbor.position[0].y
          relativeCartesian.z = this.data.position[0].z - neighbor.position[0].z

          const relativePolar = cartesianToPolar(relativeCartesian.x, relativeCartesian.y, relativeCartesian.z)

          return {
            ...neighbor,
            relativeCartesian: new Vector3(relativeCartesian.x, 0, relativeCartesian.z).normalize(),
            relativePolar
          }
        })
        // sort by distance
        .sort((a, b) => a.relativePolar.radius > b.relativePolar.radius ? 1 : -1)

      return neighbors.slice(0, maxNeighbors)
    }
  },

  created () {
    this.basePos = new Vector2()
    this.lastPos = new Vector2()
    this.deltaMove = 0

    this.$hub.on('transition:on', this.onTransition)
  },

  async mounted () {
    this.hitbox = new Mesh(new SphereGeometry(50, 10, 10), new MeshBasicMaterial({
      depthTest: false,
      depthWrite: false,
      side: BackSide,
      color: 0xffffff
    }))
    this.hitbox.visible = false
    webGL.scene.add(this.hitbox)

    this.mainSphere = new Mesh(new SphereGeometry(30, 30, 30), new MeshBasicMaterial({
      depthTest: false,
      depthWrite: false,
      side: BackSide,
      color: 0xffffff
    }))

    this.mainSphere.scale.x = -1
    this.mainSphere.rotation.y = Math.PI * .5
    webGL.scene.add(this.mainSphere)
    this.transitionSphere = new Mesh(new SphereGeometry(15, 30, 30), new MeshBasicMaterial({
      depthTest: false,
      depthWrite: false,
      side: BackSide,
      color: 0xffffff,
      transparent: true
    }))
    this.transitionSphere.scale.x = -1
    this.transitionSphere.rotation.y = Math.PI * .5
    webGL.scene.add(this.transitionSphere)

    this.$el.addEventListener(this.$device.pointerdown, this.onPointerdown)
    this.$el.addEventListener(this.$device.pointerup, this.onPointerup)
    this.$el.addEventListener(this.$device.pointermove, this.onPointermove)

    await this.onInit()

    webGL.camera.look.set(webGL.camera.position.x, webGL.camera.position.y, webGL.camera.position.z + 1)
    this.cameraLook = new CameraLook(webGL.camera, this.$device.isTouch)
  },

  methods: {
    async onTransition ({ from, to }) {
      this.isTransition = true
      const next = this.$store.getters['data/view'](to.params.pano)
      const delta = new Vector3(
        webGL.camera.position.x - next.position[0].x,
        webGL.camera.position.y - next.position[0].y,
        webGL.camera.position.z - next.position[0].z
      ).normalize()

      // console.log(delta)

      await textureLib.loadMap(next.image[0].url).then(texture => {
        this.mainSphere.material.map = texture
        this.mainSphere.material.needsUpdate = true
      })

      if (this.transitionPosition) { this.transitionPosition.kill() }
      if (this.transitionOpacity) { this.transitionOpacity.kill() }

      webGL.camera.computeLook(webGL.scene)
      this.cameraLook.reinit()

      this.transitionPosition = gsap.to(this.transitionSphere.position, {
        x: this.transitionSphere.position.x + delta.x * 7,
        y: this.transitionSphere.position.y + delta.y * 7,
        z: this.transitionSphere.position.z + delta.z * 7,
        duration: setup.duration,
        ease: 'power3.in',
        onComplete: () => {
          setTimeout(() => {
            this.$hub.emit('transition:off')
          }, 200)
        }
      })

      this.transitionOpacity = gsap.fromTo(this.transitionSphere.material, {
        opacity: 1
      }, {
        opacity: 0,
        duration: setup.duration * .2,
        delay: setup.duration * .8
      })
    },

    onInit () {
      if (!this.data?.image[0]?.url) {
        console.warn('missing data', this.data)
        return
      }
      return new Promise(async resolve => {
        await textureLib.loadMap(this.data.image[0].url).then(texture => {
          this.mainSphere.material.map?.dispose()
          this.mainSphere.material.map = this.transitionSphere.material.map = texture
          this.mainSphere.material.needsUpdate = this.transitionSphere.material.needsUpdate = true
        })

        this.$ga.send('navigation', 'Keypoint', 'view', this.$route.params.pano)

        this.cameraPos.x = webGL.camera.position.x = this.data.position[0].x
        this.cameraPos.y = webGL.camera.position.y = this.data.position[0].y
        this.cameraPos.z = webGL.camera.position.z = this.data.position[0].z

        this.hitbox.position.copy(webGL.camera.position)
        this.mainSphere.position.copy(webGL.camera.position)
        this.transitionSphere.position.copy(webGL.camera.position)
        this.isTransition = false

        resolve()
      })
    },

    onPointerdown (e) {
      if (!this.cameraLook || !this.$route.name.includes('Pano')) { return }
      this.cameraLook.onPointerdown(e)
      this.lastPos = new Vector2(this.$device.pointer.absolute.x, this.$device.pointer.absolute.y)
      this.deltaMove = 0
    },

    onPointerup (e) {
      if (!this.cameraLook || !this.$route.name.includes('Pano') || this.isTransition) { return }
      this.cameraLook.onPointerup(e)
      webGL.camera.computeLook(webGL.scene)

      // we just check if has not move
      if (this.deltaMove <= 2 && this.indexClickable !== -1) {
        // this shitty hack is to prevent navigation
        // from keypoint to keypoint instead of
        // going to media or question
        this.clickTimeout = setTimeout(() => {
          if (this.isCustomNeighbors) {
            this.$router.push({
              ...this.$local('Pano'),
              params: {
                pano: this.neighbors[this.indexClickable].dacia360[0].slug
              }
            })
          }
          else {
            this.$router.push({
              ...this.$local('Pano'),
              params: {
                pano: this.neighbors[this.indexClickable].slug
              }
            })
          }
        }, 200)
      }
    },

    onPointermove (e) {
      if (!this.cameraLook || !this.$route.name.includes('Pano') || this.isTransition) { return }
      this.cameraLook.onPointermove(e)

      const pos = new Vector2(this.$device.pointer.absolute.x, this.$device.pointer.absolute.y)
      this.deltaMove += this.lastPos.distanceTo(pos)
      this.lastPos = pos

      const intersection = pointer.getIntersectPosition(this.hitbox)

      if (intersection === null) {return}

      this.pointerPos.x = intersection.x
      this.pointerPos.y = intersection.y
      this.pointerPos.z = intersection.z

      const relativeCartesian = webGL.camera.position.clone().sub(intersection)
      const relativePolar = cartesianToPolar(relativeCartesian.x, relativeCartesian.y, relativeCartesian.z)

      if (relativePolar.azimuth < 1.3) {
        console.log('relativePolar.azimuth too small')
        this.indexClickable = -1
        return
      }

      let closest = {
        index: -1,
        dist: Infinity
      }

      this.neighbors.forEach((neighbor, index) => {
        const dist = Math.abs(neighbor.relativePolar.polar - relativePolar.polar)
        if (dist < closest.dist) {
          closest = {
            index: index,
            dist: dist
          }
        }
      })

      this.indexClickable = closest.index
      // if (closest.dist < 1) {
      //   this.indexClickable = closest.index
      // }
      // else {
      //   this.indexClickable = -1
      // }
    },

    onMediaKeypoint (to) {
      this.isTransition = true
      webGL.camera.computeLook(webGL.scene)
      const next = this.$store.getters['data/keypoint'](to.params.media)

      this.gsapLook = gsap.to(webGL.camera.look, {
        x: next.position[0].x,
        y: next.position[0].y,
        z: next.position[0].z,
        duration: 2,
        delay: .2,
        ease: 'power3.inOut',
        onComplete: () => {
          this.isTransition = false
        }
      })
    },

    onClose () {
       this.$router.push({...this.$local('Pano'), params: this.$route.params})
    }
  },

  watch: {
    data: {
      handler(newValue, oldValue) {
        this.onInit()
      },
      deep: true
    },
    $route (to, from) {
      clearTimeout(this.clickTimeout)

      if (from.name.includes('Pano') && to.name.includes('Media')) {
        this.onMediaKeypoint(to, from)
      }

      if (from.name.includes('Media') && to.name.includes('Pano')) {
        webGL.camera.computeLook(webGL.scene)
        this.cameraLook.reinit()
      }
    }
  },

  beforeUnmount () {
    webGL.scene.remove(this.hitbox)
    webGL.scene.remove(this.mainSphere)
    webGL.scene.remove(this.transitionSphere)

    this.cameraLook.destroy()

    this.$el.removeEventListener(this.$device.pointerdown, this.onPointerdown)
    this.$el.removeEventListener(this.$device.pointerup, this.onPointerup)
    this.$el.removeEventListener(this.$device.pointermove, this.onPointermove)
  }
}

function computeDist (keypoints, dataPosition) {
  return keypoints.map(keypoint => {
    return {
      ...keypoint,
      dist: new Vector3(dataPosition[0].x, dataPosition[0].y, dataPosition[0].z).distanceTo(new Vector3(keypoint.position[0].x, keypoint.position[0].y, keypoint.position[0].z))
    }
  })
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.debug-panel
  position absolute
  bottom 0
  left 0
  z-index 100
  // width 100px

.keypoint-view
  &__switch
    position absolute
    top $spacings.md
    left 50%
    transform translateX(-50%)
</style>