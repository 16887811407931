export const endpage = `
  id
  title
  subTitle: sousTitre
  image {
    ... on default_Asset {
      id
      url
      alt
    }
  }
  position {
    x
    y
    z
  }
  look {
    x
    y
    z
  }
  wysiwyg
  cta: CTA {
    text: col1
    url: col2
  }
`

export const config = `
  id
  title
  stMainColor
  stSecondColor
  tutorials: stTutorial {
    ... on stTutorial_tutorial_BlockType {
      id
      titre
      text
      image {
        ... on default_Asset {
          id
          url
          alt
        }
      }
    }
  }
  timeQuestion
  object3dGlobal {
    url
  }
  object3dLow {
    url
  }
  scale
  zoom
  position {
    x
    y
    z
  }
  look {
    x
    y
    z
  }
  losePoint
  winPoint
  backgroundSound {
    url
  }
  trueSound {
    url
  }
  falseSound {
    url
  }
  CTASound {
    url
  }
`

export const categories = `
  id
  groupHandle
  title
  image {
    ... on default_Asset {
      id
      url
      alt
    }
  }
`

export const homepage = `
  id
  title
  subtitle: sousTitre
  CTALabel
  image {
    ... on default_Asset {
      id
      url
      alt
    }
  }
  multipleTexts {
    ... on multipleTexts_multipleTexts_BlockType {
      id
      text
    }
  }
  position {
    x
    y
    z
  }
  look {
    x
    y
    z
  }
  video {
    ... on default_Asset {
      id
      url
      alt
    }
  }
`

export const basePage = `
  id
  title
  wysiwyg
  image {
    ... on default_Asset {
      id
      url
      alt
    }
  }
`

export const answerCategories = `
  id
  title
  shortTitle
  CTAs: ctaList {
    text: col2
    url: col1
  }
  content: stContentAnswerCategory {
    ... on stContentAnswerCategory_subTitle_BlockType {
      id
      title: subTitle
      type: typeHandle
    }
    ... on stContentAnswerCategory_image_BlockType {
      id
      image {
        ... on default_Asset {
          id
          url
          alt
        }
      }
      type: typeHandle
    }
    ... on stContentAnswerCategory_text_BlockType {
      id
      text
      type: typeHandle
    }
  }
`

export const keypoint = `
  id
  title
  slug
  subtitle: sousTitre
  position {
    x
    y
    z
  }
  look {
    x
    y
    z
  }
  scale
  zoom
  image {
    ... on default_Asset {
      id
      url
      alt
    }
  }
  video {
    ... on default_Asset {
      id
      url
      alt
    }
  }
  multipleTexts {
    ... on multipleTexts_multipleTexts_BlockType {
      id
      text
    }
  }
  questions: stQuestionEntry {
    ... on stQuestion_stQuestionEntry_Entry {
      id
      title
      textWin
      textLose
      media: media {
        ... on default_Asset {
          id
          url
          alt
        }
      }
      cta: CTA {
        text: col1
        url: col2
      }
      answers: stAnswer {
        ... on stAnswer_stAnswerMatrix_BlockType {
          id
          value: trueFalse
          label: answer
          categories: answerCategory {
            id
            slug
          }
        }
      }
    }
  }
  stIntermediatePoint {
    ... on stIntermediatePoint_stIntermediatePointMatrix_BlockType {
      look {
        x:col1
        y:col2
        z:col3
      }
      position {
        x:col1
        y:col2
        z:col3
      }
    }
  }
  categories: stCategories {
    ... on stCategories_Category {
      ${categories}
    }
  }
`

export const view = `
  id
  slug
  position {
    x:col1
    y:col2
    z:col3
  }
  image {
    id
    url
  }
  customNeighbors: daciaCustomNeighbors {
    ... on daciaCustomNeighbors_daciaCustomNeighbors_BlockType {
      position {
        x: col1
        y: col2
        z: col3
      }
      dacia360 {
        ... on dacia360_dacia360_Entry {
          id
          slug
        }
      }
    }
  }
  customKeypoints: stkeypointEntries {
    ... on stKeypoint_stKeypoint_Entry {
      id
      slug
      title
      position {
        x:col1
        y:col2
        z:col3
      }
      questions: stQuestionEntry {
        id
      }
    }
  }
`
